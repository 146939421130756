<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Managers/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="col-12">
              <div v-if="!userSearchStatus" class="position-relative" style="min-height: 60vh">
                <div class="position-absolute " style="top: 50%;transform:translateY(-50%); left: 0;right: 0">
                  <h5 class="text-center mb-2">To create an Managers on the system, start by first inputting the
                    specified email address or phone number.</h5>
                  <div class="row center-div mx-auto">
                    <div class="col-lg-10 col-sm-12 ">
                      <div class="has-icon-left position-relative">
                        <input type="text" id="userSearch" class="form-control" autocomplete="off"
                               v-model="userSearchQuery"
                               placeholder="Type full email address or phone number">
                        <div class="form-control-position"><i class='bx bx-search-alt'></i></div>
                      </div>
                      <div v-if="errors.existingRegisteredManager" class="text-danger">
                        {{ errors.existingRegisteredManager }}
                      </div>

                      <div v-if="registeredEmailDomains.length > 0" class="email-domain-list">
                        <div v-for="(registeredEmailDomain, index) in registeredEmailDomains" :key="index">
                          <p @click="margeUserSearchQuery(registeredEmailDomain)" class="mb-0 email-domain-list-item">
                            {{ registeredEmailDomain }}</p>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-12 mt-2 mt-lg-0">
                      <button :disabled="userSearchQuery === ''" @click="searchUser" type="button"
                              class="btn btn-block btn-primary">Submit
                      </button>
                    </div>
                  </div>
                  <div v-if="existingRegisteredManager.id" class="row center-div mx-auto">

                    <div class="col-12 mt-1">
                      <hr>
                      <h5 class="col-12 d-flex flex-row bx-flashing text-warning">
                        <i class='bx bx-info-circle' style="line-height: 1.5rem !important;"></i>
                        <span>&nbsp;Manager Already Exist!</span>
                      </h5>
                      <p class="col-12 d-md-flex justify-md-content-start align-items-center">
                        <strong>Manager Info:</strong>
                        <span>&nbsp;{{ existingRegisteredManagerDetails }}&nbsp;&nbsp;</span>
                        <router-link v-if="existingRegisteredManager.id"
                                     :to="can('manager-view') ? {name: 'appManagerView', params: {id: existingRegisteredManager.id }} : '#'"
                                     :title="can('manager-view') ? 'View Manager' : 'View Forbidden'">
                          <span class="glow d-flex align-items-center"><i class='bx bx-show'></i>&nbsp;View</span>
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <!-- left side numbering steps of the process start. -->
                  <div class="col-lg-2 step-divider">
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 1 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">1
                      </div>
                      <div class="ml-1" :class="tabStep >= 1 ? 'text-primary' : ''">User Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 1 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 2 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">2
                      </div>
                      <div class="ml-1" :class="tabStep >= 2 ? 'text-primary' : ''">Address Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 2 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 3 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">3
                      </div>
                      <div class="ml-1" :class="tabStep >= 3 ? 'text-primary' : ''">Employee Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 3 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 4 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">4
                      </div>
                      <div class="ml-1" :class="tabStep >= 4 ? 'text-primary' : ''">Bank Account Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 4 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 5 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">5
                      </div>
                      <div class="ml-1" :class="tabStep >= 5 ? 'text-primary' : ''">Store Managers Details</div>
                    </div>
                    <div class="step-line d-flex justify-content-center ">
                      <div :class="tabStep > 5 ? 'inner-line-primary-border' : 'inner-line-secondary-border'"
                           class="inner-line "></div>
                    </div>
                    <div class="d-flex align-items-center cursor-pointer">
                      <div :class="tabStep >= 6 ? 'active-step' : 'inactive-step'"
                           class="step-number d-flex justify-content-center align-items-center flex-shrink-0">6
                      </div>
                      <div class="ml-1" :class="tabStep >= 6 ? 'text-primary' : ''">Preview Details</div>
                    </div>
                  </div>
                  <!-- left side numbering steps of the process end. -->
                  <!-- right side response steps of the process starts. -->
                  <div class="col-lg-10 mt-3 mt-lg-0">
                    <div class="row" v-if="tabStep === 1">
                      <div class="col-12">
                        <h5>User Information</h5>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>First name *</label>
                          <input v-model="postUserData.first_name" type="text" class="form-control text-capitalize"
                                 placeholder="Enter first name">
                          <div class="text-danger" v-if="errors.user.firstName">{{ errors.user.firstName }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Last name *</label>
                          <input v-model="postUserData.last_name" type="text" class="form-control text-capitalize"
                                 placeholder="Enter last name">
                          <div class="text-danger" v-if="errors.user.lastName">{{ errors.user.lastName }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Phone Number *</label>
                          <input v-model="postUserData.phone_number" :readonly="userPhoneFilled" max="10" type="tel"
                                 class="form-control " placeholder="ie. 0412345678"
                                 aria-describedby="userAlreadyExistWarning">
                          <small v-if="userFound" id="userAlreadyExistWarning"
                                 class="form-text text-warning d-flex flex-row"><strong><i
                              class='bx bx-info-circle'></i></strong>&nbsp;Previously registered users! Any changes will
                            update this user's data.</small>
                          <div class="text-danger" v-if="errors.user.phoneNumber">{{ errors.user.phoneNumber }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Email Address *</label>
                          <input v-model="postUserData.email" :readonly="userEmailFilled" type="email"
                                 class="form-control" placeholder="ie. example@example.com.au">
                          <div class="text-danger" v-if="errors.user.email">{{ errors.user.email }}</div>
                          <div class="text-danger" v-if="errors.address.userId">{{ errors.address.userId }}</div>
                          <div class="text-danger" v-if="errors.employee.userId">{{ errors.employee.userId }}</div>
                          <div class="text-danger" v-if="errors.manager.userId">{{ errors.manager.userId }}</div>
                          <div class="text-danger" v-if="errors.manager.employeeId">{{
                              errors.manager.employeeId
                            }}
                          </div>
                          <div class="text-danger" v-if="errors.bankAccount.userId">{{
                              errors.bankAccount.userId
                            }}
                          </div>
                          <div class="text-danger" v-if="errors.storeManager.userId">{{
                              errors.storeManager.userId
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Date of Birth</label>
                          <div class="d-flex align-items-center date-picker-wrapper">
                            <div class="d-flex align-items-center">
                              <i class='bx bx-calendar'></i>
                            </div>
                            <div class="w-100 pl-1 ">
                              <div class="w-100 ">
                                <DatePicker v-model="postUserData.date_of_birth"
                                            :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}"
                                            :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                  <template v-slot="{ inputValue, inputEvents }">
                                    <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                                  </template>
                                </DatePicker>
                              </div>
                            </div>
                          </div>
                          <div class="text-danger" v-if="errors.user.dateOfBirth">{{ errors.user.dateOfBirth }}</div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Role</label>
                          <VueMultiselect v-model="selectedRole" class="" :options="roles" :close-on-select="true"
                                          placeholder="Select a role" label="name" track-by="name" :show-labels="false"
                                          :allow-empty="false"/>
                          <div class="text-danger" v-if="errors.user.roleId">{{ errors.user.roleId }}</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button @click="jumpToStart" class="btn btn-light-primary">Prev</button>
                          <button @click="createSingleUser" :disabled="!isPostUserAndPostManagerTypeDataFilled"
                                  class="btn btn-light-primary ml-1">Next
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep === 2">
                      <div class="col-12">
                        <h5>Address Information</h5>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Address</label>
                          <small v-if="!addressInformationSelectDiv && hasUserAddresses"
                                 @click="addressInformationSelectDiv = !addressInformationSelectDiv"
                                 class="text-primary">&nbsp;(Click to select address from existing one)</small>
                          <small v-if="addressInformationSelectDiv"
                                 @click="addressInformationSelectDiv = !addressInformationSelectDiv"
                                 class="text-primary">&nbsp;(Click to create a new address)</small>
                          <VueMultiselect v-if="addressInformationSelectDiv" v-model="selectedAddress"
                                          :options="addressList" :close-on-select="true" placeholder="Select Address"
                                          label="name" track-by="value" :show-labels="false" :allow-empty="false"/>
                          <vue-google-autocomplete v-else :enable-geolocation="true" ref="address" :country="['au']"
                                                   id="map" classname="form-control"
                                                   placeholder="Please type full address."
                                                   v-on:placechanged="googleMapApiResponseData"/>
                          <div class="text-danger" v-if="errors.employee.addressId">{{
                              errors.employee.addressId
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData"
                           class="col-12 d-flex flex-row bx-flashing text-warning">
                        <i class='bx bx-info-circle' style="line-height: 1.5rem !important;"></i>
                        <span>&nbsp;Please modify the following address fields, if there anything wrong!</span>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12">
                        <div class="form-group">
                          <label>Street Address *</label>
                          <input v-model="postAddressData.street" type="text" class="form-control"
                                 placeholder="Enter street address"/>
                          <div class="text-danger" v-if="errors.address.street">{{ errors.address.street }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6 ">
                        <div class="form-group">
                          <label>Suburbs/ Territory *</label>
                          <input v-model="postAddressData.suburb" type="text" class="form-control"
                                 placeholder="Enter suburbs/ territory"/>
                          <div class="text-danger" v-if="errors.address.suburb">{{ errors.address.suburb }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>State *</label>
                          <VueMultiselect v-model="selectedState" :allowEmpty="false" :options="stateList"
                                          :close-on-select="true" placeholder="Select state" label="name"
                                          track-by="value" :show-labels="false"/>
                          <div class="text-danger" v-if="errors.address.state">{{ errors.address.state }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>Post Code *</label>
                          <input v-model="postAddressData.post_code" type="text" class="form-control"
                                 placeholder="Enter code" required/>
                          <div class="text-danger" v-if="errors.address.postCode">{{ errors.address.postCode }}</div>
                        </div>
                      </div>
                      <div v-if="!addressInformationSelectDiv && hasAddressData" class="col-12 col-lg-3 col-md-6">
                        <div class="form-group">
                          <label>Country *</label>
                          <input v-model="postAddressData.country" type="text" class="form-control" readonly required/>
                          <div class="text-danger" v-if="errors.address.country">{{ errors.address.country }}</div>
                        </div>
                      </div>

                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button v-if="addressInformationSelectDiv" class="btn btn-light-primary ml-1"
                                  :disabled="!selectedAddress.value" @click="tabNext">Next
                          </button>
                          <button v-else class="btn btn-light-primary ml-1" :disabled="!hasAddressData"
                                  @click="createSingleAddress">Next
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep === 3">
                      <div class="col-12"><h5>Employee Type & Salary Information</h5></div>
                      <div class="col-12">
                        <div class="form-group">
                          <label>Employee Type</label>
                          <VueMultiselect v-model="selectedEmployeeType" class="" :options="employeeTypeList"
                                          :close-on-select="true" placeholder="Select the employee type" label="name"
                                          track-by="name" :show-labels="false" :allow-empty="false"/>
                          <div class="text-danger" v-if="errors.employee.type">{{ errors.employee.type }}</div>
                        </div>
                      </div>
                      <div v-if="hasEmployeeType" class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Salary Type *</label>
                          <input readonly type="text" :value="postEmployeeData.salary_type === 0 ? 'Fixed' : 'Hourly'"
                                 class="form-control">
                          <div class="text-danger" v-if="errors.employee.salaryType">{{
                              errors.employee.salaryType
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-if="hasEmployeeType" class="col-12 col-md-6">
                        <div class="form-group">
                          <label>{{ `${salaryAmountType} *` }}</label>
                          <input v-model="employeeSalaryAmount" min="0.00" step="0.001" type="number"
                                 class="form-control">
                          <div class="text-danger" v-if="errors.employee.salaryAmount">{{
                              errors.employee.salaryAmount
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-if="hasEmployeeType" class="col-12"><h5>ID Information</h5></div>
                      <div v-if="hasEmployeeType" class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Designation *</label>
                          <input v-model="postEmployeeData.designation" type="text" class="form-control "
                                 placeholder="Enter designation">
                          <div class="text-danger" v-if="errors.employee.designation">{{
                              errors.employee.designation
                            }}
                          </div>
                        </div>
                      </div>
                      <div v-if="hasEmployeeType" class="col-12 col-md-6">
                        <div class="form-group">
                          <label>ID Number</label>
                          <input v-model="postEmployeeData.id_number" type="text" class="form-control "
                                 placeholder="Enter a unique ID">
                          <div class="text-danger" v-if="errors.employee.idNumber">{{ errors.employee.idNumber }}</div>
                        </div>
                      </div>
                      <div v-if="hasEmployeeType && needAbn" class="col-12"><h5>ABN Information</h5></div>
                      <div v-if="hasEmployeeType && needAbn" class="col-12">
                        <div class="form-group">
                          <label>ABN Information *</label>
                          <input v-model="postEmployeeData.abn" type="text" class="form-control "
                                 placeholder="Enter ABN">
                          <div class="text-danger" v-if="errors.employee.abn">{{ errors.employee.abn }}</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" :disabled="!isPostEmployeeDataFilled"
                                  @click="createSingleEmployee()">Next
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep === 4">
                      <div class="col-12"><h5>Bank Account Information</h5></div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Bank Name</label>
                          <input v-model="postBankAccountData.bank_name" type="text" class="form-control "
                                 placeholder="Enter bank name.">
                          <div class="text-danger" v-if="errors.bankAccount.bankName">{{
                              errors.bankAccount.bankName
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Account Name</label>
                          <input v-model="postBankAccountData.account_name" type="text" class="form-control "
                                 placeholder="Enter account name.">
                          <div class="text-danger" v-if="errors.bankAccount.accountName">
                            {{ errors.bankAccount.accountName }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>Account Number</label>
                          <input v-model="postBankAccountData.account_number" type="text" class="form-control "
                                 placeholder="Enter account number.">
                          <div class="text-danger" v-if="errors.bankAccount.accountNumber">
                            {{ errors.bankAccount.accountNumber }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label>BSB</label>
                          <input v-model="postBankAccountData.bsb" type="text" class="form-control "
                                 placeholder="Enter BSB.">
                          <div class="text-danger" v-if="errors.bankAccount.bsb">{{ errors.bankAccount.bsb }}</div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" :disabled="!isPostBankAccountDataFilled"
                                  @click="existingRegisteredBankAccountDataHasChanged? createSingleBankAccount() : tabNext()">
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep === 5">
                      <div class="col-12"><h5>Store Manager Information</h5></div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <div class="controls">
                            <label>Store Manager Status</label>
                            <div class="" style="margin-top: .5rem">
                              <div
                                  class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                <div>
                                  <input v-model="employeeStoreManagerStatus" type="checkbox"
                                         class="custom-control-input" id="employeeStoreManagerStatusCheckbox">
                                  <label class="custom-control-label mr-1"
                                         for="employeeStoreManagerStatusCheckbox"></label>
                                </div>
                                <span
                                    class="font-medium-1">{{
                                    employeeStoreManagerStatus ? "Active" : "Inactive"
                                  }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="text-danger" v-if="errors.storeManager.status">{{
                              errors.storeManager.status
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" @click="tabNext">Next</button>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="tabStep === 6">
                      <div class="col-12"><h3>Preview Details</h3></div>
                      <div class="col-12">
                        <table class="table table-responsive table-borderless table-transparent">
                          <tbody>
                          <tr>
                            <td class="bold">First Name</td>
                            <td>:&nbsp;{{ postUserData.first_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Last Name</td>
                            <td>:&nbsp;{{ postUserData.last_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Phone Number</td>
                            <td>:&nbsp;{{ postUserData.phone_number }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Email</td>
                            <td>:&nbsp;{{ postUserData.email }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Date of Birth</td>
                            <td>:&nbsp;{{ postUserData.date_of_birth }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Manager Role</td>
                            <td>:&nbsp;{{ selectedRole.name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Address</td>
                            <td>:&nbsp;{{ addressInformationSelectDiv ? selectedAddress.name : filledAddress }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Designation</td>
                            <td>:&nbsp;{{ postEmployeeData.designation }}</td>
                          </tr>
                          <tr>
                            <td class="bold">ID Number</td>
                            <td>:&nbsp;{{ postEmployeeData.id_number }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Employee Type</td>
                            <td>:&nbsp;{{ selectedEmployeeType.name ?? selectedEmployeeType.name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Salary Type</td>
                            <td>:&nbsp;{{ salaryType }}</td>
                          </tr>
                          <tr>
                            <td class="bold">{{ salaryAmountType }}</td>
                            <td>:&nbsp;{{ employeeSalaryAmount }}</td>
                          </tr>
                          <tr v-if="needAbn">
                            <td class="bold">ABN</td>
                            <td>:&nbsp;{{ postEmployeeData.abn }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Bank Name</td>
                            <td>:&nbsp;{{ postBankAccountData.bank_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Bank Account Name</td>
                            <td>:&nbsp;{{ postBankAccountData.account_name }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Bank Account Number</td>
                            <td>:&nbsp;{{ postBankAccountData.account_number }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Bank Account BSB</td>
                            <td>:&nbsp;{{ postBankAccountData.bsb }}</td>
                          </tr>
                          <tr>
                            <td class="bold">Store Manager Status</td>
                            <td>:&nbsp;{{ employeeStoreManagerStatus ? "Active" : "Inactive" }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-12">
                        <div class="d-flex justify-content-end">
                          <button class="btn btn-light-primary" @click="tabPrev">Prev</button>
                          <button class="btn btn-light-primary ml-1" @click="createSingleManager()">Create Manager
                          </button>
                        </div>
                      </div>

                    </div>
                  </div>
                  <!-- right side response steps of the process end. -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout';

// mixins
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";

// package
import {DatePicker} from 'v-calendar';
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import VueMultiselect from 'vue-multiselect';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "ManagerCreateNew",
  mixins: [ShowToastMessage, Loader, Authorization],
  props: ['routeName'],
  components: {
    AppLayout,
    DatePicker,
    VueGoogleAutocomplete,
    VueMultiselect,

  },

  data() {
    return {
      userSearchQuery: '',
      userSearchEmailDomainPartQuery: '',
      userSearchStatus: false,
      userFound: false,
      userEmailFilled: false,
      userPhoneFilled: false,
      tabStep: 1,
      userId: null,
      employeeId: null,
      existingRegisteredManager: {
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      },

      postUserData: {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      },

      existingRegisteredUserData: {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      },

      addressInformationSelectDiv: false,
      hasUserAddresses: false,

      selectedEmployeeType: {
        'value': '',
        'name': 'Any',
      },

      selectedAddress: {
        value: '',
        name: 'None'
      },

      postAddressData: {
        user_id: '',
        street: '',
        suburb: '',
        state: '',
        post_code: '',
        country: '',
      },

      selectedState: {
        value: '',
        name: 'None'
      },

      postEmployeeData: {
        address_id: '',
        user_id: '',
        type: '',
        abn: '',
        id_number: '',
        designation: '',
        salary_type: 0,
        salary_amount: 0.00,
      },

      existingRegisteredEmployeeData: {
        address_id: '',
        user_id: '',
        type: '',
        abn: '',
        id_number: '',
        designation: '',
        salary_type: '',
        salary_amount: '',
      },

      postManagerData: {
        user_id: '',
        employee_id: '',
        status: 1,
      },
      employeeTypeList: [
        {
          value: 0,
          name: "Permanent Full Time",
        },
        {
          value: 1,
          name: "Permanent Part Time",
        },
        {
          value: 2,
          name: "Contractual",
        },
        {
          value: 3,
          name: "Casual",
        },
      ],

      salaryTypeList: [
        {
          value: 0,
          name: "Fixed",
        },
        {
          value: 1,
          name: "Hourly",
        },
      ],

      postBankAccountData: {
        user_id: '',
        bank_name: '',
        account_name: '',
        account_number: '',
        bsb: '',

      },

      postStoreManagerData: {
        manager_id: '',
        employee_id: '',
        user_id: '',
        status: 0,
      },

      employeeSalaryAmount: 0.00,

      employeeStoreManagerStatus: false,
      existingEmployeeStoreManagerStatus: false,

      existingRegisteredBankAccountData: {
        user_id: '',
        bank_name: '',
        account_name: '',
        account_number: '',
        bsb: '',
      },


      selectedRole: {
        value: '',
        name: 'None'
      },


      errors: {
        existingRegisteredManager: '',
        user: {
          roleId: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          dateOfBirth: '',
        },
        address: {
          userId: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        employee: {
          addressId: '',
          userId: '',
          type: '',
          abn: '',
          idNumber: '',
          designation: '',
          salaryType: '',
          salaryAmount: '',
          status: '',
        },
        manager: {
          userId: '',
          employeeId: '',
          status: '',
        },
        bankAccount: {
          userId: '',
          bankName: '',
          accountName: '',
          accountNumber: '',
          bsb: '',
        },
        storeManager: {
          managerId: '',
          employeeId: '',
          userId: '',
          status: '',
        },
      },

      getSettingsParams: {
        type: ['default'],
        key: ['default_state'],
      },

      getRolesParams: {
        type: 3 // manager type
      },

      getUsersParams: {
        email_phone_where_has_user_additional_infos_query: '',
        with_relation: ['role', 'addresses', 'bankAccount', 'manager', 'employee', 'employee.address', 'employee.user'],
      },
    }
  },

  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      users: 'appUsers/users',
      registeredEmailDomains: 'appUsers/registeredEmailDomains'
    }),
    redirectRouteName() {
      return this.can('manager-view-any') ? 'appManagerList' : 'appManagerCreate';
    },

    registeredEmailDomains() {

      let registeredEmailDomains = this.$store.getters['appUsers/registeredEmailDomains'].email_domains;

      if (this.userSearchEmailDomainPartQuery !== '' && Array.isArray(registeredEmailDomains)) {
        return this.$store.getters['appUsers/registeredEmailDomains'].email_domains.filter((str) => {
          return str.toLowerCase().indexOf(this.userSearchEmailDomainPartQuery.toLowerCase()) >= 0;
        });
      }

      if (this.userSearchEmailDomainPartQuery !== '' && (typeof registeredEmailDomains) === 'object') {
        return Object.values(registeredEmailDomains).filter((str) => {
          return str.toLowerCase().indexOf(this.userSearchEmailDomainPartQuery.toLowerCase()) >= 0;
        });
      }

      return [];
    },

    existingRegisteredManagerDetails() {
      return `${this.existingRegisteredManager.firstName} ${this.existingRegisteredManager.lastName} (${this.existingRegisteredManager.email} | ${this.existingRegisteredManager.phoneNumber})`;
    },

    isPostUserAndPostManagerTypeDataFilled() {
      return !!(this.postUserData.first_name && this.postUserData.last_name && (this.postUserData.email || this.postUserData.phone_number) && this.postUserData.role_id !== '');
    },

    existingRegisteredUserDataHasChanged() {
      return this.postUserData.first_name !== this.existingRegisteredUserData.first_name ||
          this.postUserData.last_name !== this.existingRegisteredUserData.last_name ||
          this.postUserData.email !== this.existingRegisteredUserData.email ||
          this.postUserData.phone_number !== this.existingRegisteredUserData.phone_number ||
          this.postUserData.date_of_birth !== this.existingRegisteredUserData.date_of_birth;
    },

    existingRegisteredEmployeeDataHasChanged() {
      return this.postEmployeeData.address_id !== this.existingRegisteredEmployeeData.address_id ||
          this.postEmployeeData.user_id !== this.existingRegisteredEmployeeData.user_id ||
          this.postEmployeeData.type !== this.existingRegisteredEmployeeData.type ||
          this.postEmployeeData.abn !== this.existingRegisteredEmployeeData.abn ||
          this.postEmployeeData.id_number !== this.existingRegisteredEmployeeData.id_number ||
          this.postEmployeeData.designation !== this.existingRegisteredEmployeeData.designation ||
          this.postEmployeeData.salary_type !== this.existingRegisteredEmployeeData.salary_type ||
          this.postEmployeeData.salary_amount !== this.existingRegisteredEmployeeData.salary_amount;
    },

    roles() {
      let activeRoles = this.$store.getters['appRoles/roles'].map((roleObj) => {
        let roleId = roleObj.id;
        let roleName = roleObj.name;

        return {
          value: roleId,
          name: roleName
        };
      });

      return [{
        value: '',
        name: 'None'
      }, ...activeRoles];
    },

    addressList() {
      let userAddresses = this.users && this.users[0] && this.users[0].addresses ?
          this.users[0].addresses.map((address) => {
            let addressId = address.id;
            let addressStreet = address.street;
            let addressSuburb = address.suburb;
            let addressState = address.state.toUpperCase();
            let addressPostCode = address.post_code;
            let addressCountry = address.country;

            return {
              value: addressId,
              name: `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`
            };
          }) : [];

      return [
        {
          value: '',
          name: 'None'
        },
        ...userAddresses
      ];
    },

    stateList() {
      return this.$store.getters['appSettings/settingDefaultState'].value
    },

    hasAddressData() {
      return !!(this.postAddressData.street || this.postAddressData.suburb || this.postAddressData.state || this.postAddressData.post_code);
    },

    isPostEmployeeDataFilled() {
      return !!(this.postEmployeeData.type !== '' && this.postEmployeeData.designation && (this.postEmployeeData.salary_type === 0 || this.postEmployeeData.salary_type === 1) && this.postEmployeeData.salary_amount >= 0);
    },

    isPostBankAccountDataFilled() {
      return !!(this.postBankAccountData.bank_name && this.postBankAccountData.account_name && this.postBankAccountData.account_number && this.postBankAccountData.bsb);
    },

    filledAddress() {
      let addressStreet = this.postAddressData.street;
      let addressSuburb = this.postAddressData.suburb;
      let addressState = this.postAddressData.state.toUpperCase();
      let addressPostCode = this.postAddressData.post_code;
      let addressCountry = this.postAddressData.country;

      return `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    hasEmployeeType() {
      return this.postEmployeeData.type !== '';
    },

    salaryType() {
      if (this.postEmployeeData.type === 0 || this.postEmployeeData.type === 1) {
        return 'Fixed';
      } else {
        return 'Hourly';
      }
    },

    salaryAmountType() {
      if (this.postEmployeeData.type === 0 || this.postEmployeeData.type === 1) {
        return 'Salary Amount';
      } else {
        return 'Payment Rate (Hourly)';
      }
    },

    needAbn() {
      return this.postEmployeeData.type === 2 || this.postEmployeeData.type === 3;
    },

    existingStoreManagerDataHasChanged() {
      return this.employeeStoreManagerStatus !== this.existingEmployeeStoreManagerStatus;
    },

    existingRegisteredBankAccountDataHasChanged() {
      return this.postBankAccountData.user_id !== this.existingRegisteredBankAccountData.user_id ||
          this.postBankAccountData.bank_name !== this.existingRegisteredBankAccountData.bank_name ||
          this.postBankAccountData.account_name !== this.existingRegisteredBankAccountData.account_name ||
          this.postBankAccountData.account_number !== this.existingRegisteredBankAccountData.account_number ||
          this.postBankAccountData.bsb !== this.existingRegisteredBankAccountData.bsb;
    },

    enableForProductCommission() {
      return this.postEmployeeData.type === 2 || this.postEmployeeData.type === 3;
    },
  },

  watch: {
    userSearchQuery(userSearchQuery) {
      this.userSearchEmailDomainPartQuery = userSearchQuery.includes('@') ? userSearchQuery.substring(userSearchQuery.lastIndexOf('@') + 1) : '';
    },

    selectedRole(selectedRole) {
      this.postUserData.role_id = selectedRole.value;
    },

    selectedEmployeeType(selectedEmployeeType) {
      this.postEmployeeData.type = selectedEmployeeType.value;

      if (selectedEmployeeType.value === 0 || selectedEmployeeType.value === 1) {
        this.postEmployeeData.salary_type = 0; // fixed
        this.postEmployeeData.abn = '';
      } else {
        this.postEmployeeData.salary_type = 1; // hourly
        this.postEmployeeData.abn = this.existingRegisteredEmployeeData.abn;
      }
    },

    selectedAddress(selectedAddress) {
      this.postEmployeeData.address_id = selectedAddress.value ?? null;
    },

    selectedState(selectedState) {
      this.postAddressData.state = selectedState.value;
    },

    employeeSalaryAmount(employeeSalaryAmount) {
      this.postEmployeeData.salary_amount = employeeSalaryAmount * 100;
    },

    employeeStoreManagerStatus() {
      this.postStoreManagerData.status = this.employeeStoreManagerStatus ? 1 : 0;
    },

  },

  methods: {
    ...mapActions({
      resetUser: 'appUsers/resetUser',
      resetUsers: 'appUsers/resetUsers',
      resetRoles: 'appRoles/resetRoles',
      resetAddress: 'appAddresses/resetAddress',
      resetAddresses: 'appAddresses/resetAddresses',
      resetEmployee: 'appEmployees/resetEmployee',
      resetBankAccount: 'appBankAccounts/resetBankAccount',
      resetManager: 'appManagers/resetManager',


      postUser: 'appUsers/postUser',
      putUser: 'appUsers/putUser',
      postAddress: 'appAddresses/postAddress',
      postEmployee: 'appEmployees/postEmployee',
      putEmployee: 'appEmployees/putEmployee',
      postBankAccount: 'appBankAccounts/postBankAccount',
      postManager: 'appManagers/postManager',
      postStoreManager: 'appStoreManagers/postStoreManager',

      getSettings: 'appSettings/getSettings',
      getUsers: 'appUsers/getUsers',
      getRoles: 'appRoles/getRoles',
      getRegisteredEmailDomains: 'appUsers/getRegisteredEmailDomains',
    }),

    async margeUserSearchQuery(registeredEmailDomain) {
      let firstPart = this.userSearchQuery.substring(0, this.userSearchQuery.lastIndexOf('@'));
      this.userSearchQuery = `${firstPart}@${registeredEmailDomain}`;
      await this.searchUser();
      this.userSearchEmailDomainPartQuery = '';
    },

    async tabPrev() {
      this.tabStep--;
    },

    async tabNext() {
      this.tabStep++;
    },

    async tabJump(tabToJump) {
      this.tabStep = tabToJump;
    },

    async setExistingRegisteredManager(user, managerId) {
      this.existingRegisteredManager = {
        id: managerId,
        userId: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phoneNumber: user.phone_number,
      };
    },

    async resetExistingRegisteredManager() {
      this.existingRegisteredManager = {
        id: '',
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      };
    },

    async setExistingRegisteredManagerError() {
      this.errors.existingRegisteredManager = 'The user is already registered as a manager.';
    },
    async resetExistingRegisteredManagerError() {
      this.errors.existingRegisteredManager = '';
    },


    async setExistingRegisteredUserData(user) {
      this.existingRegisteredUserData.role_id = user.role && user.role.id ? user.role.id : '';
      this.existingRegisteredUserData.first_name = user.first_name ?? '';
      this.existingRegisteredUserData.last_name = user.last_name ?? '';
      this.existingRegisteredUserData.email = user.email ?? '';
      this.existingRegisteredUserData.phone_number = user.phone_number ?? '';
      this.existingRegisteredUserData.date_of_birth = user.date_of_birth ?? '';
    },

    async setPostUserData(user) {
      this.postUserData.role_id = user.role && user.role.id ? user.role.id : '';
      this.postUserData.first_name = user.first_name ?? '';
      this.postUserData.last_name = user.last_name ?? '';
      this.postUserData.email = user.email ?? '';
      this.postUserData.phone_number = user.phone_number ?? '';
      this.postUserData.date_of_birth = user.date_of_birth ?? '';
    },
    async resetPostUserData() {
      this.postUserData = {
        role_id: '',
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        date_of_birth: '',
      };
    },

    async resetSelectedRole() {
      this.selectedRole = {
        value: '',
        name: 'None'
      };
    },

    async resetSelectedAddress() {
      this.selectedAddress = {
        value: '',
        name: 'None'
      };
    },

    async setPostEmployeeData(employee) {
      this.postEmployeeData.address_id = employee.address && employee.address.id ? employee.address.id : '';
      this.postEmployeeData.user_id = employee.user && employee.user.id ? employee.user.id : '';
      this.postEmployeeData.type = employee.type ? this.employeeTypeList.find(type => type.name === employee.type).value : '';
      this.postEmployeeData.abn = employee.abn ?? '';
      this.postEmployeeData.id_number = employee.id_number ?? '';
      this.postEmployeeData.designation = employee.designation ?? '';
      this.postEmployeeData.salary_type = employee.salary_type ? this.salaryTypeList.find(type => type.name === employee.salary_type).value : '';
      this.postEmployeeData.salary_amount = employee.salary_amount ?? '';
    },

    async setExistingRegisteredEmployeeData(employee) {
      this.existingRegisteredEmployeeData.address_id = employee.address && employee.address.id ? employee.address.id : '';
      this.existingRegisteredEmployeeData.user_id = employee.user && employee.user.id ? employee.user.id : '';
      this.existingRegisteredEmployeeData.type = employee.type ? this.employeeTypeList.find(type => type.name === employee.type).value : '';
      this.existingRegisteredEmployeeData.abn = employee.abn ?? '';
      this.existingRegisteredEmployeeData.id_number = employee.id_number ?? '';
      this.existingRegisteredEmployeeData.designation = employee.designation ?? '';
      this.existingRegisteredEmployeeData.salary_type = employee.salary_type ? this.salaryTypeList.find(type => type.name === employee.salary_type).value : '';
      this.existingRegisteredEmployeeData.salary_amount = employee.salary_amount ?? '';

    },

    async setPostBankAccountData(bankAccount) {
      this.postBankAccountData.user_id = bankAccount.user && bankAccount.user.id ? bankAccount.user.id : this.userId;
      this.postBankAccountData.bank_name = bankAccount.bank_name ?? '';
      this.postBankAccountData.account_name = bankAccount.account_name ?? '';
      this.postBankAccountData.account_number = bankAccount.account_number ?? '';
      this.postBankAccountData.bsb = bankAccount.bsb ?? '';
    },

    async setExistingRegisteredBankAccountData(bankAccount) {
      this.existingRegisteredBankAccountData.user_id = bankAccount.user && bankAccount.user.id ? bankAccount.user.id : this.userId;
      this.existingRegisteredBankAccountData.bank_name = bankAccount.bank_name ?? '';
      this.existingRegisteredBankAccountData.account_name = bankAccount.account_name ?? '';
      this.existingRegisteredBankAccountData.account_number = bankAccount.account_number ?? '';
      this.existingRegisteredBankAccountData.bsb = bankAccount.bsb ?? '';
    },

    async resetPostEmployeeData() {
      this.postEmployeeData = {
        address_id: '',
        user_id: '',
        type: '',
        abn: '',
        id_number: '',
        designation: '',
        salary_type: 0,
        salary_amount: 0.00,
      };
    },

    async resetErrors() {
      this.errors = {
        existingRegisteredManager: '',
        user: {
          roleId: '',
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          dateOfBirth: '',
        },
        address: {
          userId: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        employee: {
          addressId: '',
          userId: '',
          type: '',
          abn: '',
          idNumber: '',
          designation: '',
          salaryType: '',
          salaryAmount: '',
          status: 1,
        },
        manager: {
          userId: '',
          employeeId: '',
          status: '',
        },
        bankAccount: {
          userId: '',
          bankName: '',
          accountName: '',
          accountNumber: '',
          bsb: '',
        },
        storeManager: {
          managerId: '',
          employeeId: '',
          userId: '',
          status: '',
        },
      }
    },

    async jumpToStart() {
      this.userSearchStatus = false;
      this.userFound = false;
      this.userEmailFilled = false;
      this.userPhoneFilled = false;
      this.tabStep = 1;
      this.userId = null;

      await this.resetUsers();
      await this.resetUser();
      await this.resetPostUserData();
      await this.resetSelectedRole();
      await this.resetSelectedAddress();
      await this.resetErrors();

      this.addressInformationSelectDiv = false;
      this.hasUserAddresses = false;

    },

    async validateEmailString(string) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string);
    },

    async searchUser() {
      this.loader(true);
      this.userSearchEmailDomainPartQuery = '';

      this.getUsersParams.email_phone_where_has_user_additional_infos_query = this.userSearchQuery;
      await this.getUsers(this.getUsersParams);
      if (this.users.length > 0) {

        let user = this.users[0];

        if (user && user.manager && user.manager.id) {
          await this.setExistingRegisteredManagerError();
          await this.setExistingRegisteredManager(user, user.manager.id);
          this.loader(false);
          return;
        }

        await this.resetExistingRegisteredManagerError()
        await this.resetExistingRegisteredManager();

        this.userFound = true;

        this.userId = user.id;

        await this.setPostUserData(user);
        await this.setExistingRegisteredUserData(user);

        if (user.role) {
          this.selectedRole.value = user.role.id;
          this.selectedRole.name = user.role.name;
        }

        if (user.employee) {
          this.employeeId = user.employee.id;
          await this.setPostEmployeeData(user.employee);
          await this.setExistingRegisteredEmployeeData(user.employee);
          let employeeType = this.employeeTypeList.find(type => type.name === user.employee.type);
          if (employeeType) {
            this.selectedEmployeeType.value = employeeType.value;
            this.selectedEmployeeType.name = employeeType.name;
          }

          this.employeeSalaryAmount = (user.employee.salary_amount / 100).toFixed(2);
          this.postManagerData.employee_id = this.employeeId;
          this.postStoreManagerData.employee_id = this.employeeId;
        }

        if (user.employee && user.employee.address) {
          let address = await this.addressList.find(address => address.value === user.employee.address.id);
          if (address) {
            this.selectedAddress.value = address.value;
            this.selectedAddress.name = address.name;
          }
        }

        if (user.bankAccount) {
          await this.setPostBankAccountData(user.bankAccount);
          await this.setExistingRegisteredBankAccountData(user.bankAccount);
        }

        this.postAddressData.user_id = this.userId;
        this.postEmployeeData.user_id = this.userId;
        this.postBankAccountData.user_id = this.userId;
        this.postManagerData.user_id = this.userId;
        this.postStoreManagerData.user_id = this.userId;
      }

      if (this.users.length <= 0) {
        if (await this.validateEmailString(this.userSearchQuery)) {
          this.postUserData.email = this.userSearchQuery;
          this.userEmailFilled = true;
        }

        if (!await this.validateEmailString(this.userSearchQuery)) {
          this.postUserData.phone_number = this.userSearchQuery;
          this.userPhoneFilled = true;
        }
      }

      if (this.addressList.length > 1) {
        this.addressInformationSelectDiv = true;
        this.hasUserAddresses = true;
      }

      this.userSearchStatus = true;

      this.loader(false);
    },

    async createSingleUser() {
      if (!this.userId) {
        this.loader(true);
        await this.postUser(this.postUserData).then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            let user = this.$store.getters['appUsers/user'];
            await this.setExistingRegisteredUserData(user);

            this.userId = user.id;
            this.postAddressData.user_id = this.userId;
            this.postEmployeeData.user_id = this.userId;
            this.postBankAccountData.user_id = this.userId;
            this.postStoreManagerData.user_id = this.userId;
            this.postManagerData.user_id = this.userId;
            await this.tabNext();
          }

          this.errors.user.roleId = response.errors && response.errors.role_id ? response.errors.role_id[0] : '';
          this.errors.user.firstName = response.errors && response.errors.first_name ? response.errors.first_name[0] : '';
          this.errors.user.lastName = response.errors && response.errors.last_name ? response.errors.last_name[0] : '';
          this.errors.user.email = response.errors && response.errors.email ? response.errors.email[0] : '';
          this.errors.user.phoneNumber = response.errors && response.errors.phone_number ? response.errors.phone_number[0] : '';
          this.errors.user.dateOfBirth = response.errors && response.errors.date_of_birth ? response.errors.date_of_birth[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }
        });
        this.loader(false);
        return;
      }

      if (this.userId && this.existingRegisteredUserDataHasChanged) {
        this.loader(true);
        let dataObj = {
          id: this.userId,
          data: this.postUserData,
        };
        await this.putUser(dataObj).then(async (response) => {
          if (response.status === 200) {
            let user = this.$store.getters['appUsers/user'];
            await this.setExistingRegisteredUserData(user);

            this.postAddressData.user_id = this.userId;
            this.postEmployeeData.user_id = this.userId;
            this.postBankAccountData.user_id = this.userId;
            this.postStoreManagerData.user_id = this.userId;
            this.postManagerData.user_id = this.userId;
            await this.tabNext();
          }

          this.errors.user.roleId = response.errors && response.errors.role_id ? response.errors.role_id[0] : '';
          this.errors.user.firstName = response.errors && response.errors.first_name ? response.errors.first_name[0] : '';
          this.errors.user.lastName = response.errors && response.errors.last_name ? response.errors.last_name[0] : '';
          this.errors.user.email = response.errors && response.errors.email ? response.errors.email[0] : '';
          this.errors.user.phoneNumber = response.errors && response.errors.phone_number ? response.errors.phone_number[0] : '';
          this.errors.user.dateOfBirth = response.errors && response.errors.date_of_birth ? response.errors.date_of_birth[0] : '';

          this.showToastMessage(response);
        });
        this.loader(false);
        return;
      }

      if (this.userId && !this.existingRegisteredUserDataHasChanged) {
        await this.tabNext();
      }
    },

    googleMapApiResponseData: function (googleMapApiResponseData) {
      this.loader(true);
      let streetNumber = googleMapApiResponseData.street_number ?? '';
      let streetName = googleMapApiResponseData.route ?? '';
      let suburbs = googleMapApiResponseData.locality ?? '';
      let state = googleMapApiResponseData.administrative_area_level_1 ?? '';
      let postCode = googleMapApiResponseData.postal_code ?? '';
      let country = googleMapApiResponseData.country ?? '';

      this.postAddressData.street = streetNumber ? `${streetNumber} ${streetName}` : streetName;
      this.postAddressData.suburb = suburbs;
      this.postAddressData.state = state.toLowerCase();
      this.postAddressData.post_code = postCode;
      this.postAddressData.country = country;

      if (state) {
        let addressStateIndex = this.stateList.findIndex(item => item.value === state.toLowerCase());
        if (addressStateIndex !== -1) {
          let addressState = this.stateList[addressStateIndex];

          this.selectedState.value = addressState.value;
          this.selectedState.name = addressState.name;
        }
      }
      this.loader(false);
    },

    async createSingleAddress() {
      this.loader(true);
      await this.postAddress(this.postAddressData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {

          let address = this.$store.getters['appAddresses/address'];
          this.postEmployeeData.address_id = address.id;

          let newAddress = {
            value: address.id,
            name: `${address.street}, ${address.suburb} ${address.state} ${address.post_code}, ${address.country}`
          };

          this.addressList.push(newAddress);
          this.hasUserAddresses = true;

          await this.tabNext();
        }

        this.errors.address.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.address.street = response.errors && response.errors.street ? response.errors.street[0] : '';
        this.errors.address.suburb = response.errors && response.errors.suburb ? response.errors.suburb[0] : '';
        this.errors.address.state = response.errors && response.errors.state ? response.errors.state[0] : '';
        this.errors.address.postCode = response.errors && response.errors.post_code ? response.errors.post_code[0] : '';
        this.errors.address.country = response.errors && response.errors.country ? response.errors.country[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.address.userId) {
          await this.tabJump(1);
        }

      });
      this.loader(false);
    },

    async createSingleEmployee() {
      if (!this.employeeId) {
        this.loader(true);
        await this.postEmployee(this.postEmployeeData).then(async (response) => {
          if (response.status === 200 || response.status === 201) {
            let employee = this.$store.getters['appEmployees/employee']
            await this.setExistingRegisteredEmployeeData(employee);

            this.postStoreManagerData.employee_id = employee.id;
            this.postManagerData.employee_id = employee.id;
            await this.tabNext();
          }

          this.errors.employee.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
          this.errors.employee.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
          this.errors.employee.type = response.errors && response.errors.type ? response.errors.type[0] : '';
          this.errors.employee.abn = response.errors && response.errors.abn ? response.errors.abn[0] : '';
          this.errors.employee.idNumber = response.errors && response.errors.id_number ? response.errors.id_number[0] : '';
          this.errors.employee.designation = response.errors && response.errors.designation ? response.errors.designation[0] : '';
          this.errors.employee.salaryType = response.errors && response.errors.salary_type ? response.errors.salary_type[0] : '';
          this.errors.employee.salaryAmount = response.errors && response.errors.salary_amount ? response.errors.salary_amount[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }

          if (this.errors.employee.addressId) {
            await this.tabJump(2);
            this.loader(false);
            return;
          }
          if (this.errors.employee.userId) {
            await this.tabJump(1);
            this.loader(false);
          }
        });
        this.loader(false);
        return;
      }

      if (this.employeeId && this.existingRegisteredEmployeeDataHasChanged) {
        this.loader(true);
        let dataObj = {
          id: this.employeeId,
          data: this.postEmployeeData,
        };
        await this.putEmployee(dataObj).then(async (response) => {
          if (response.status === 200) {
            let employee = this.$store.getters['appEmployees/employee'];
            await this.setExistingRegisteredEmployeeData(employee);
            this.postStoreManagerData.employee_id = employee.id;
            this.postManagerData.employee_id = employee.id;

            await this.tabNext();
          }

          this.errors.employee.addressId = response.errors && response.errors.address_id ? response.errors.address_id[0] : '';
          this.errors.employee.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
          this.errors.employee.type = response.errors && response.errors.type ? response.errors.type[0] : '';
          this.errors.employee.abn = response.errors && response.errors.abn ? response.errors.abn[0] : '';
          this.errors.employee.idNumber = response.errors && response.errors.id_number ? response.errors.id_number[0] : '';
          this.errors.employee.designation = response.errors && response.errors.designation ? response.errors.designation[0] : '';
          this.errors.employee.salaryType = response.errors && response.errors.salary_type ? response.errors.salary_type[0] : '';
          this.errors.employee.salaryAmount = response.errors && response.errors.salary_amount ? response.errors.salary_amount[0] : '';

          if (response.message) {
            this.showToastMessage(response);
          }

          if (this.errors.employee.addressId) {
            await this.tabJump(2);
            this.loader(false);
            return;
          }
          if (this.errors.employee.userId) {
            await this.tabJump(1);
            this.loader(false);
          }
        });
        this.loader(false);
        return;
      }

      if (this.employeeId && !this.existingRegisteredEmployeeDataHasChanged) {
        await this.tabNext();
      }
    },

    async createSingleBankAccount() {
      this.loader(true);
      await this.postBankAccount(this.postBankAccountData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {
          await this.tabNext();
        }

        this.errors.bankAccount.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.bankAccount.bank_account_id = response.errors && response.errors.bank_account_id ? response.errors.bank_account_id[0] : '';
        this.errors.bankAccount.account_name = response.errors && response.errors.account_name ? response.errors.account_name[0] : '';
        this.errors.bankAccount.account_number = response.errors && response.errors.account_number ? response.errors.account_number[0] : '';
        this.errors.bankAccount.bsb = response.errors && response.errors.bsb ? response.errors.bsb[0] : '';
        this.errors.bankAccount.account_type = response.errors && response.errors.account_type ? response.errors.account_type[0] : '';
        this.errors.bankAccount.account_type_id = response.errors && response.errors.account_type_id ? response.errors.account_type_id[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.bankAccount.userId) {
          await this.tabJump(1);
        }
      });
      this.loader(false);
    },

    async createSingleStoreManager() {
      this.loader(true);
      await this.postStoreManager(this.postStoreManagerData).then(async (response) => {
        if (response.status === 200 || response.status === 201) {
          await this.tabNext();
        }

        this.errors.storeManager.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.storeManager.employeeId = response.errors && response.errors.employee_id ? response.errors.employee_id[0] : '';
        this.errors.storeManager.managerId = response.errors && response.errors.manager_id ? response.errors.manager_id[0] : '';
        this.errors.storeManager.status = response.errors && response.errors.status ? response.errors.status[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.storeManager.userId) {
          await this.tabJump(1);
        }
      });
      this.loader(false);
    },

    async createSingleManager() {
      this.loader(true);
      await this.postManager({...this.postStoreManagerData,status:1}).then(async (response) => {
        if (response.status === 201) {
          let managerId = this.$store.getters['appManagers/manager'].id;
          this.postStoreManagerData.manager_id = managerId;

          if (this.postStoreManagerData.user_id && this.postStoreManagerData.employee_id && this.postStoreManagerData.manager_id && this.postStoreManagerData.status) {
            await this.createSingleStoreManager();
          }

          const toastObj = {
            message: 'Manager created successfully.',
            type: 'success'
          };

          this.showToastMessage(toastObj);

          await this.$router.push({
            name: this.redirectRouteName,
          });

        }

        this.errors.manager.userId = response.errors && response.errors.user_id ? response.errors.user_id[0] : '';
        this.errors.manager.employeeId = response.errors && response.errors.employee_id ? response.errors.employee_id[0] : '';

        if (response.message) {
          this.showToastMessage(response);
        }

        if (this.errors.manager.userId || this.errors.manager.employeeId) {
          await this.tabJump(1);
        }
      });

      this.loader(false);
    },

    async getSettingList() {
      await this.getSettings(this.getSettingsParams);
    },

    async getRolesList() {
      await this.getRoles(this.getRolesParams);
    },


    async getRegisterEmailDomainList() {
      await this.getRegisteredEmailDomains();
    },
  },

  async mounted() {
    await this.getSettingList();
    await this.getRolesList();
    await this.getRegisterEmailDomainList();
  },

  async beforeMount() {
    await this.resetUser();
    await this.resetUsers();
    await this.resetRoles();
    await this.resetAddress();
    await this.resetAddresses();
    await this.resetManager();
  }
}
</script>

<style scoped>
.center-div {
  width: 85%;
}

.step-number {
  width: 38px;
  height: 38px;
  color: white;
  border-radius: 0.375rem
}

.step-line {
  width: 38px;
  height: 38px;

}

.inner-line {
  display: inline-block !important;
  /* border-left: 1px solid #5a8dee; */
  height: 100%;
}

.inner-line-primary-border {
  border-left: 1px solid #5a8dee;
}

.inner-line-secondary-border {
  border-left: 1px solid gray;
}

.active-step {
  background-color: #5a8dee;
}

.inactive-step {
  background-color: #90a5cc;
}

.step-divider {
  border-right: 1px solid #d4d8dd;
}

.email-domain-list {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 1rem;
  background-color: gray;
  max-height: 217px;
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
  padding: 0.5rem 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #d2ddec;
  border-right: 1px solid #d2ddec;
  border-bottom: 1px solid #d2ddec;
  z-index: 30;
  -webkit-box-shadow: 0 4px 5px 0 rgb(0 0 50%);
  -moz-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 4px 5px 0 rgb(0 0 25%);
}

.email-domain-list-item {
  padding-left: 1rem;
  cursor: pointer;
  margin: 2px 0;
  color: black;
}

.email-domain-list-item:hover {
  background-color: #5a8dee;
  color: white;

}

@media only screen and (max-width: 600px) {
  .center-div {
    width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .step-divider {
    border-right: none;
    border-bottom: 1px solid #d4d8dd;
    padding-bottom: 27px;
  }
}
</style>